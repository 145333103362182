import { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";

import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { useHttpPost } from "./hooks.js";
import * as lodash from "lodash";

import { SizeMe } from "react-sizeme";

import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import * as u from "./utility.js";
import { DEFAULT_FONT_SIZE } from "./constants.js";

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.js",
//   import.meta.url
// ).toString();

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const pdfOptions = {
  cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
  standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts/`,
  cMapPacked: true,
};

function getLastOpenedPage(item, index) {
  return u.getUiProp(item, "page", index);
}

function PdfView({ numPages, file, item, fontSize }) {
  const initialFactor = u.getImageSizeFactor(item, file.index);
  const initialPage = getLastOpenedPage(item, file.index);

  const [pageNumber, setPageNumber] = useState(initialPage);
  const [hover, setHover] = useState(false);
  const [sizeFactor, setSizeFactor] = useState(initialFactor);
  const [commitedFactor, setCommitedFactor] = useState(initialFactor);
  const [commitedPage, setCommitedPage] = useState(initialPage);
  const [renderedPageNumber, setRenderedPageNumber] = useState(null);
  const httpPost = useHttpPost();

  const defaultWidth = 350;
  const isLoading = renderedPageNumber !== pageNumber;

  function changePage(offset) {
    setPageNumber((prev) => lodash.clamp(prev + offset, 1, numPages));
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const handleSizeDown = (e) => {
    setSizeFactor((prev) => prev / 1.3);
  };

  const handleSizeUp = (e) => {
    setSizeFactor((prev) => prev * 1.3);
  };

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);

    const request = async (props) => {
      try {
        httpPost("label", {
          setmessageprops: {
            PK: item.PK,
            SK: item.SK,
            ...props,
          },
        });
      } catch (err) {}
    };

    // dynamodb does not support floats, so we work around that limitation
    if (Math.abs(sizeFactor - commitedFactor) > 0.01) {
      const commit = Math.round(sizeFactor * 100);
      setCommitedFactor(commit / 100);
      let props = {};
      props[`st_ui/f${file.index}`] = commit;
      request(props);
    }

    if (pageNumber != commitedPage) {
      setCommitedPage(pageNumber);

      let props = {};
      props[`st_ui/page/${file.index}`] = pageNumber;
      request(props);
    }
  };

  return (
    <>
      {numPages > 0 && (
        <SizeMe monitorHeight>
          {({ size }) => (
            <Box
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              sx={{ /*background: "black",*/ width: "100%" }}
            >
              <Box sx={{ position: "relative", display: "inline-block" }}>
                {item && hover && (
                  <>
                    <Box sx={{ position: "absolute", left: 0, zIndex: 5 }}>
                      <Stack spacing={0} direction="row">
                        <IconButton
                          variant="contained"
                          onClick={handleSizeUp}
                          size="small"
                        >
                          <AddCircleIcon
                            fontSize="inherit"
                            sx={{ borderRadius: "50%", background: "white" }}
                          />
                        </IconButton>
                        <IconButton
                          variant="contained"
                          onClick={handleSizeDown}
                          size="small"
                        >
                          <RemoveCircleIcon
                            fontSize="inherit"
                            sx={{ borderRadius: "50%", background: "white" }}
                          />
                        </IconButton>
                      </Stack>
                    </Box>
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        zIndex: 5,
                        width: "100%",
                      }}
                    >
                      <Stack
                        spacing={0}
                        direction={"row"}
                        justifyContent={"center"}
                      >
                        <IconButton
                          variant="contained"
                          onClick={previousPage}
                          size="small"
                          disabled={pageNumber == 1}
                        >
                          <ArrowLeftIcon
                            fontSize="inherit"
                            sx={{ borderRadius: "50%", background: "white" }}
                          />
                        </IconButton>
                        <IconButton
                          variant="contained"
                          onClick={nextPage}
                          size="small"
                          disabled={pageNumber == numPages}
                        >
                          <ArrowRightIcon
                            fontSize="inherit"
                            sx={{ borderRadius: "50%", background: "white" }}
                          />
                        </IconButton>
                      </Stack>
                    </Box>
                  </>
                )}
                {isLoading && renderedPageNumber && (
                  <Page
                    key={renderedPageNumber}
                    pageNumber={renderedPageNumber}
                    width={lodash.clamp(
                      defaultWidth *
                        sizeFactor *
                        (fontSize / DEFAULT_FONT_SIZE),
                      50,
                      size.width ? size.width : 100
                    )}
                  ></Page>
                )}
                <Page
                  key={pageNumber}
                  pageNumber={pageNumber}
                  width={lodash.clamp(
                    defaultWidth * sizeFactor * (fontSize / DEFAULT_FONT_SIZE),
                    50,
                    size.width ? size.width : 100
                  )}
                  onRenderSuccess={() => setRenderedPageNumber(pageNumber)}
                  className={`${isLoading ? "loadingPage" : ""}`}
                ></Page>
              </Box>
            </Box>
          )}
        </SizeMe>
      )}
    </>
  );
}

export function Pdf({ data, file, item, onError, fontSize }) {
  const [numPages, setNumPages] = useState(0);

  function onDocumentLoadSuccess(pdf) {
    setNumPages(pdf.numPages);
  }

  const handleClick = (e) => {
    if (e.metaKey) {
      e.stopPropagation();
      if (u.detectBrowser() === "chrome") {
        var wnd = window.open("", "_blank");
        wnd.document.open();
        wnd.document.write(
          `<iframe width="100%" height="100%" src="data:application/pdf;base64,${data}"/>`
        );
        wnd.document.close();
      } else {
        window.open(
          `data:application/pdf;base64,${data}`,
          "_blank",
          "noreferrer"
        );
      }
    }
  };

  useEffect(() => {}, [data]);

  const getPdfData = () => {
    if (data) {
      // file was downloaded from d3
      return `data:application/pdf;base64,${data}`;
    } else if (file.uploaded_file) {
      // file has been just uploaded
      return file.uploaded_file;
    }

    return file; // uploaded file from preview
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Document
        file={getPdfData()}
        options={pdfOptions}
        onLoadSuccess={onDocumentLoadSuccess}
        onClick={handleClick}
        onLoadError={onError}
        onSourceError={onError}
        loading={""}
      >
        {numPages > 0 && (
          <PdfView
            numPages={numPages}
            file={file}
            item={item}
            fontSize={fontSize}
          />
        )}
      </Document>
    </Box>
  );
}
