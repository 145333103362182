import "./App.css";

import { useState, useEffect, useRef } from "react";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import { getTimestamp } from "./storydat";
import * as u from "./utility";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { isPinned, hasNotificationToday } from "./storydat";

export function TimelineChips({ item, forRecentStories }) {
  const [created, setCreated] = useState(-1);
  const [updated, setUpdated] = useState(-1);

  useEffect(() => {
    setCreated(daysElapsedSinceDate(getTimestamp(item)));
    let ts =
      !forRecentStories && "st_updated_ts" in item
        ? item.st_updated_ts
        : item.SK2;
    setUpdated(daysElapsedSince(ts));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const bothChips = () => {
    const insignificant = Math.abs(created - updated) / created < 0.1;
    return (
      !forRecentStories &&
      elapsedDaysToString(created) !== elapsedDaysToString(updated) &&
      !insignificant
    );
  };

  const color = (days) => {
    if (forRecentStories) {
      return undefined;
    }

    let c = undefined;
    if (days < 3) {
      c = "palegreen";
    } else if (days < 7) {
      c = "wheat";
    } else if (days < 30) {
      c = "pink";
    }
    return c;
  };

  const freshness = (updated) => {
    const days = elapsedDaysToString(updated);
    // if (days === "now") {
    //   return bothChips() ? "fresh" : "";
    // }
    // return  days + (bothChips() ? " fresh " : "")
    return days;
  };

  return (
    <Stack direction="row" alignItems="center" gap={0.2}>
      {bothChips() && created >= 0 && (
        <Chip
          size="small"
          disabled={true}
          label={elapsedDaysToString(created) + " old"}
        />
      )}

      {updated >= 0 && (
        <Chip
          size="small"
          disabled={true}
          sx={color(updated) ? { background: color(updated) } : {}}
          label={freshness(updated)}
        />
      )}
    </Stack>
  );
}

const elapsedDaysToString = (days) => {
  if (days === 0) {
    return "now";
  } else if (days < 30) {
    return `${days}d`;
  } else if (days < 365) {
    return `${Math.floor(days / 30)}m`;
  } else {
    return `${Math.floor(days / 365)}y`;
  }
};

const daysElapsedSinceDate = (date) => {
  const start = Date.parse(date);
  const now = Date.now();
  if (now < start) {
    return 0;
  }
  const days = Math.floor((now - start) / (1000 * 60 * 60 * 24));
  return days;
};

const daysElapsedSince = (ts) => {
  if (!ts || !ts.length) {
    return -1;
  }

  const start = u.timestampToDate(ts);
  const now = Date.now();
  if (now < start) {
    return 0;
  }
  const days = Math.floor((now - start) / (1000 * 60 * 60 * 24));
  return days;
};

export function StoryChip({ item, rowSelected }) {
  return (
    <>
      <Box
        sx={{
          whiteSpace: "nowrap",
          p: 0.2,
          pl: 2,
          pr: 2,
          m: 0,
          background: u.mixColors(
            "#aaaaaa",
            getRowColor(item, rowSelected),
            0.1
          ),
          borderRadius: "16px",
        }}
      >
        <Stack direction={"row"} alignItems="center">
          <Typography fontSize={8} color="grey">
            STORY-
          </Typography>
          <Typography fontSize={14} color="#909090">
            {Number(item.SK4)}
          </Typography>
        </Stack>
      </Box>
      {/* <Chip
      size="small"
      disabled={true}
      label={u.getStoryKey(item)}
      onClick={() => {
        navigator.clipboard.writeText(u.getStoryKey(item));
      }}
    /> */}
    </>
  );
}

export const getRowColor = (item, isRowSelected) => {
  if (hasNotificationToday(item)) {
    return "#f5e0e0";
  }

  const colors = {
    day: "#e8e8e8",
    two: "#f1f1f1",
    three: "#f1f1f1",
    week: "#f8f8f8",
    other: "#ffffff",
  };

  if (isRowSelected) {
    return "#d9e5ed";
  }

  if (isPinned(item)) {
    return "#dadada";
  }

  const ts = item.SK2;
  const start = u.timestampToDate(ts);
  const now = Date.now();
  if (now < start) {
    return colors.day;
  }

  const days = (now - start) / (1000 * 60 * 60 * 24);
  if (days < 1) {
    return colors.day;
  }

  if (days < 2) {
    return colors.two;
  }

  if (days < 4) {
    return colors.three;
  }

  if (days < 7) {
    return colors.week;
  }

  return colors.other;
};
