import { useEffect, useRef, useState } from "react";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import KeyIcon from "@mui/icons-material/Key";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";

import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import * as lodash from "lodash";
import { useDebounce } from "use-debounce";
import parse from "html-react-parser";

import { FileUploadPreview } from "./FileUploadPreview";
import { PasswordInput } from "./Password";
import * as u from "./utility";
import log from "./logger";
import { encrypt } from "./crypto";
import {
  useHttpPostAndProject,
  useUploadFile,
  useProjectCaptions,
  useHttpPost,
} from "./hooks";

import fuzzysort from "fuzzysort";
import { DarkStoryPreview } from "./StoryDetails";

export const MAX_MESSAGE_SIZE = 4000;

function UploadErrorToast({ onRetry }) {
  return (
    <Stack direction={"row"} alignItems={"center"}>
      <Typography fontSize={12}>File upload error</Typography>
      <Button onClick={onRetry} size="small">
        RETRY
      </Button>
    </Stack>
  );
}

export function handlePasteEvent(e, processFiles, setRefs) {
  let clipboardItems = e.clipboardData.items;
  let files = [];

  let numFileAttachments = 0;

  // first handle files and text
  for (let i = 0; i < clipboardItems.length; ++i) {
    const item = clipboardItems[i];
    if (item.kind === "file") {
      const file = item.getAsFile();
      files.push(file);
      numFileAttachments += 1;
    } else if (item.kind === "string") {
      if (item.type === "text/plain") {
        const txt = e.clipboardData.getData("text");
        if (txt.length > MAX_MESSAGE_SIZE) {
          const largeTextFile = new File([txt], "description.txt", {
            type: "text/plain",
          });
          files.push(largeTextFile);
        }
      }
    }
  }

  // lastly handle html. the processFiles has to be called once
  for (let i = 0; i < clipboardItems.length && numFileAttachments == 0; ++i) {
    const item = clipboardItems[i];
    if (item.kind === "string") {
      if (item.type === "text/html") {
        const downloadHtmlImages = async (images, files) => {
          for (let i = 0; i < images.length; ++i) {
            const img = images.item(i);
            const getTopstorieName = (img) => {
              const attr = img.attributes.getNamedItem("st_filename");
              return attr ? attr.nodeValue : undefined;
            };

            try {
              const response = await fetch(img.src);
              const data = await response.blob();
              if (data.type.startsWith("image/")) {
                let nameFromUrl =
                  getTopstorieName(img) ||
                  (!img.src.startsWith("data:") && img.src.split("/").pop()) ||
                  uuidv4();

                nameFromUrl = u.sanitizeFileName(nameFromUrl);

                const filetype = data.type.split("/").pop();
                if (!nameFromUrl.endsWith("." + filetype)) {
                  nameFromUrl += "." + filetype;
                }

                const imageFile = new File([data], nameFromUrl, {
                  type: data.type,
                });
                files.push(imageFile);
              }
            } catch (e) {
              log.debug(e);
            }
          }

          if (files.length > 0) {
            processFiles(files);
          }
        };

        const parser = new DOMParser();
        const html = e.clipboardData.getData("text/html");
        const doc = parser.parseFromString(html, "text/html");
        if (doc.images.length > 0) {
          downloadHtmlImages(doc.images, files);
          files = [];
        }

        let refs = [];
        extractRefsFromHtml(doc, refs);

        if (refs.length > 0) {
          setRefs((prev) => {
            let res = [];
            let seen = new Set();

            const proc = (refs) => {
              for (let r of refs) {
                const key = r.PK + ":" + r.SK;
                if (!seen.has(key)) {
                  seen.add(key);
                  res.push(r);
                }
              }
            };

            proc(prev);
            proc(refs);
            return prev.length === res.length ? prev : res;
          });
        }
      }
    }
  }

  if (files.length > 0) {
    processFiles(files);
  }

  if (files.length === clipboardItems.length) {
    e.preventDefault();
  }
}

function TextToast({ text }) {
  return <Typography fontSize={12}>{text}</Typography>;
}

export function validateNotifyFileSize(file) {
  if (file.size > 128 * 1024 * 1024) {
    let abbrev = file.name;
    if (abbrev.length > 16) {
      abbrev = abbrev.slice(0, 13) + "...";
    }

    const text = `Sorry, the file "${abbrev}" is too big.`;
    toast.error(<TextToast text={text} />);
    return false;
  }

  return true;
}

function extractRefsFromHtml(doc, refs) {
  for (const child of doc.children) {
    const pk = child.attributes.getNamedItem("st_pk");
    if (pk) {
      const sk = child.attributes.getNamedItem("st_sk");
      const ref = { PK: pk.nodeValue, SK: sk.nodeValue };

      const found = refs.find((e) => lodash.isEqual(ref, e));
      if (!found) {
        refs.push(ref);
      }
    }
    extractRefsFromHtml(child, refs);
  }
}

const getCacheKey = (item) => {
  const key = `SendMessageBox:${item.PK}:${item.SK}`;
  return key;
};

export const unfinishedMessageFromCache = (item) => {
  const message = u.readLocalStorage(getCacheKey(item), "");
  return message;
};

const saveMessageInCache = (item, message) => {
  u.writeLocalStorage(getCacheKey(item), message);
};

const clearMessageInCache = (item) => {
  saveMessageInCache(item, "");
};

const fuzzySearch = (input, captions) => {
  const targets = Object.keys(captions);
  const res = fuzzysort.go(input, targets);
  return res.slice(0, 100).map((e) => {
    return {
      text: e.target,
      highlight: e.highlight("<font color='burlywood'><b>", "</b></font>"),
    };
  });
};

export function Suggestions({
  input,
  captions,
  onSelected,
  onHighlight,
  suggestionsRef,
  sx,
  selectedIndex,
  noScrollIntoView,
}) {
  const [found, setFound] = useState([]);
  const [highestRankingStory, setHighestRankingStory] = useState();
  const httpPost = useHttpPost();
  const [hoverStory, setHoverStory] = useState();
  const [debouncedInput] = useDebounce(input, 200);
  const menuRef = useRef();

  const searchStory = async (storyCaption) => {
    try {
      const req = { search: true, query: storyCaption };
      const resp = await httpPost("stories", req);
      const searchResult = resp.data.search;
      if (searchResult) {
        return searchResult[0];
      }
    } catch (e) {}
    return null;
  };

  useEffect(() => {
    const input = debouncedInput;
    const found = fuzzySearch(input, captions);
    setFound(found);
    suggestionsRef.current = found;
    if (found.length > 0) {
      const request = async (storyCaption) => {
        setHighestRankingStory(await searchStory(storyCaption));
      };
      const index = lodash.clamp(selectedIndex ?? 0, 0, found.length - 1);
      request(found[index].text);
    } else {
      setHighestRankingStory(null);
      if (/^-?\d+$/.test(input)) {
        const request = async () => {
          const story = await searchStory(input);
          if (story) {
            setHighestRankingStory(story);

            const text = story.st_cap || input;
            const suggestions = [{ text: text, highlight: text }];
            setFound(suggestions);
            suggestionsRef.current = suggestions;
          }
        };
        request();
      }
    }
  }, [debouncedInput, captions]);

  useEffect(() => {
    if (found.length > 0) {
      const request = async (storyCaption) => {
        setHighestRankingStory(await searchStory(storyCaption));
      };
      const index = lodash.clamp(selectedIndex ?? 0, 0, found.length - 1);

      const text = found[index].text;
      request(text);

      if (onHighlight) {
        onHighlight(text);
      }

      if (menuRef && menuRef.current && !noScrollIntoView) {
        const child = menuRef.current.children[index];
        const y = Math.max(
          child.offsetTop -
            menuRef.current.clientHeight +
            2 * child.clientHeight,
          0
        );
        menuRef.current.scroll(0, y);
      }
    }
  }, [selectedIndex, found]);

  const handleMouseEnter = (item) => {
    const request = async (storyCaption) => {
      setHoverStory(await searchStory(storyCaption));
    };
    request(item);
  };

  const handleMouseLeave = (item) => {
    // setHoverStory((prev) => prev === item ? undefined : prev);
  };

  const handleClick = (e) => {
    const target = e.target;

    // see [1167]
    if (target.tagName !== "B") {
      onSelected(target.innerText);
    } else {
      let parent = target.parentElement;
      if (parent.tagName === "FONT" && parent.parentElement.tagName === "DIV") {
        onSelected(parent.parentElement.innerText);
      } else {
        // TODO : do we want to raise an exception here?
        onSelected(target.innerText);
      }
    }
  };

  return (
    <>
      {found.length > 0 && (
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{
            backgroundColor: "#404040",
            color: "white",
            borderRadius: "5px",
            mt: 0.5,
            ...(sx ? sx : {}),
          }}
        >
          <Box sx={{ minWidth: "40%", overflow: "auto" }}>
            <MenuList
              open={input ? true : false}
              ref={menuRef}
              sx={{ maxHeight: 300, overflow: "auto" }}
            >
              {found.map(({ text, highlight }, index) => {
                return (
                  <MenuItem
                    key={text}
                    onClick={handleClick}
                    sx={{ fontSize: 11 }}
                    onMouseEnter={() => handleMouseEnter(text)}
                    onMouseLeave={() => handleMouseLeave(text)}
                    selected={index === selectedIndex}
                  >
                    <div>{parse(highlight)}</div>
                  </MenuItem>
                );
              })}
            </MenuList>
          </Box>
          {highestRankingStory && (
            <DarkStoryPreview
              item={hoverStory || highestRankingStory}
              fontSize={9}
            />
          )}
        </Stack>
      )}
    </>
  );
}

export function SuggestionsPopper({
  buffer,
  anchorRef,
  suggestionsRef,
  onSelected,
  noScrollIntoView,
  selectedIndex,
  onHighlight,
  options,
}) {
  return (
    <>
      {buffer.text && anchorRef && anchorRef.current && (
        <Popper
          anchorEl={anchorRef.current}
          open={true}
          placement="bottom-start"
          style={{ zIndex: 10000 }}
          popperOptions={{
            modifiers: [
              {
                name: "flip",
                enabled: false,
              },
            ],
          }}
        >
          <Box sx={{ width: 500 }}>
            <Suggestions
              input={buffer.text}
              captions={options}
              onSelected={onSelected}
              suggestionsRef={suggestionsRef}
              selectedIndex={selectedIndex}
              noScrollIntoView={noScrollIntoView}
              onHighlight={onHighlight}
            />
          </Box>
        </Popper>
      )}
    </>
  );
}

export function TextFieldWithSuggestions(props) {
  const [buffer, setBuffer] = useState({});
  const suggestionsRef = useRef([]);
  const inputRef = useRef();
  const [selectedSuggestion, setSelectedSuggestion] = useState(0);
  const captions = useProjectCaptions();

  const handleChange = (e) => {
    const cursor = e.target.selectionStart;
    if (e.nativeEvent.data === "[") {
      setBuffer({ start: cursor, cursor: cursor, text: "" });
    } else if (e.nativeEvent.data === "]") {
      setBuffer({});
    } else if (e.nativeEvent.inputType === "deleteContentBackward") {
      setBuffer((prev) => {
        if (cursor >= prev.start && prev.cursor === cursor + 1) {
          const text = prev.text.slice(0, -1);
          return { cursor: cursor, start: prev.start, text: text };
        } else {
          return {};
        }
      });
    } else {
      // check buffer length if it exceeds max caption size
      setBuffer((prev) => {
        if (prev.cursor + 1 === cursor) {
          const text = prev.text + e.nativeEvent.data;
          return { cursor: cursor, start: prev.start, text: text };
        } else {
          return {};
        }
      });
      setSelectedSuggestion(0);
    }

    if (props.onChange) {
      props.onChange(e);
    }
  };

  const selectPrev = () => {
    if (suggestionsRef.current && suggestionsRef.current.length) {
      const index = Math.max(0, selectedSuggestion - 1);
      setSelectedSuggestion(index);
    }
  };

  const selectNext = () => {
    if (suggestionsRef.current && suggestionsRef.current.length > 0) {
      const index = Math.min(
        suggestionsRef.current.length - 1,
        selectedSuggestion + 1
      );
      setSelectedSuggestion(index);
    }
  };

  const handleKeyDown = (e) => {
    if ((e.key === "Tab" || e.key === "Enter") && buffer.text) {
      // const suggest = fuzzySearch(buffer.text, captions);
      if (
        suggestionsRef &&
        suggestionsRef.current &&
        suggestionsRef.current.length > 0
      ) {
        const index = lodash.clamp(
          selectedSuggestion,
          0,
          suggestionsRef.current.length - 1
        );
        const suggestion = suggestionsRef.current[index].text;
        handleSuggestionSelected(suggestion);
        e.preventDefault();
      }
    } else if (e.key === "ArrowUp" || (e.ctrlKey && e.key === "k")) {
      selectPrev();
    } else if (e.key === "ArrowDown" || (e.ctrlKey && e.key === "j")) {
      selectNext();
    } else if (e.key == "Escape" && buffer.text) {
      setBuffer({});
    } else if (props.onKeyDown) {
      props.onKeyDown(e);
    }
  }; // handleKeyDown

  const handleBlur = () => {
    // give a chance to other events to be handled
    // like when you click on a suggestion, that will immediately trigger blur
    // and you'll loose an event containing the clicked suggestion
    setTimeout(() => {
      setBuffer({});
    }, 300);

    if (props.onBlur) {
      props.onBlur();
    }
  };

  const handleSuggestionSelected = (value) => {
    const ref = (props.inputRef || inputRef).current;
    ref.focus();

    const preText = ref.value.slice(0, buffer.start) + value + "]";
    const postText = ref.value.slice(buffer.cursor);
    ref.value = preText + postText;
    ref.setSelectionRange(preText.length, preText.length);

    if (props.setMessage) {
      props.setMessage(preText + postText);
    }
    setBuffer({});
  };

  const handleMouseDown = (e) => {
    setBuffer({});
  };

  const cleanProps = () => {
    let res = { ...props };
    delete res.setMessage;
    return res;
  };

  return (
    <>
      <TextField
        {...cleanProps()}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        onMouseDown={handleMouseDown}
        inputRef={props.inputRef || inputRef}
      />
      <SuggestionsPopper
        anchorRef={props.inputRef || inputRef}
        buffer={buffer}
        suggestionsRef={suggestionsRef}
        onSelected={handleSuggestionSelected}
        selectedIndex={selectedSuggestion}
        options={captions}
      />
    </>
  );
}

export function SendMessageBox({
  item,
  setMessages,
  id,
  onSent,
  droppedFiles,
  onFileRemoved,
  selectedFiles,
  setSelectedFiles,
  message,
  setMessage,
  password,
  setPassword,
  previousMessage,
  onBlur,
  onFocus,
  focused,
}) {
  const [disabled, setDisabled] = useState(!unfinishedMessageFromCache(item));
  const [refs, setRefs] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const inputRef = useRef();
  const [visible, setVisible] = useState(false);
  const [httpPost, projectName] = useHttpPostAndProject();
  const uploadToS3 = useUploadFile();

  const processTextMessage = (value) => {
    setDisabled(value.length === 0 && selectedFiles.length === 0);
    const truncated = value.slice(0, MAX_MESSAGE_SIZE);
    setMessage(truncated);
  };

  const handleChange = (e) => {
    processTextMessage(e.target.value);
  };

  const handleSend = () => {
    if (
      (!message || message.length === 0) &&
      (!selectedFiles || selectedFiles.length === 0)
    ) {
      return;
    }

    const uploadFiles = async (ts, encrypted_message) => {
      const uploaded = [];
      for (let f of selectedFiles) {
        const uri = await uploadToS3(f, projectName);
        if (uri) {
          let uploaded_file = {
            uri: uri,
            type: f.type,
            size: f.size,
            name: f.name,
          };
          if (f.detected_mimetype) {
            uploaded_file.type = f.detected_mimetype;
          }
          uploaded.push(uploaded_file);
        } else {
          return false;
        }
      }

      const uploadRequest = async (message, files) => {
        try {
          const updateUiPreferences = (message) => {
            setTimeout(() => {
              httpPost("stories", { messages: true, story: item.SK })
                .then((response) => {
                  const latest = response.data.messages.find(
                    (e) => e.SK === message.SK
                  );
                  if (latest && latest.st_ui) {
                    setMessages((prev) => {
                      const found = prev.findIndex((e) => e.SK === message.SK);
                      if (found >= 0) {
                        let res = [...prev];
                        res[found] = { ...res[found], st_ui: latest.st_ui };
                        return res;
                      } else {
                        return prev;
                      }
                    });
                  }
                })
                .catch((e) => {
                  log.debug(e);
                });
            }, 3000);
          };

          let obj = { reply: true, story: item.SK, refs: refs };
          if (files && files.length > 0) {
            obj.files = files;
          }

          if (encrypted_message) {
            obj.enc = encrypted_message;
          } else {
            if (message && message.length > 0) {
              obj.desc = message;
            }
          }

          if (item.st_source) {
            obj.forward = item.st_source;
          }

          httpPost("stories", obj).then((response) => {
            clearMessageInCache(item);
            if (onSent) {
              onSent({ ts: ts, msg: response.data.reply, text: obj.desc });
            }
            if (files && files.length > 0) {
              updateUiPreferences(response.data.reply);
            }
          });
        } catch (err) {
          log.debug(err);
        }
      };

      uploadRequest(message, uploaded);
      return true;
    }; // uploadFiles

    const ts = u.getCurrentTimestamp();
    let messageObj = {
      SK: ts,
      ts: ts,
      event_ts: ts,
      type: "message",
      refs: refs,
      dummy: true,
    };

    if (message && !password) {
      messageObj.text = message;
    }

    if (selectedFiles.length > 0) {
      messageObj.files = selectedFiles;
      messageObj.type = "topstorie_file_upload";
    }

    const proceed = async () => {
      let encrypted_message = undefined;
      if (password && message) {
        encrypted_message = await encrypt(password, message);
      }

      const attemptUpload = async () => {
        const ok = await uploadFiles(ts, encrypted_message);
        if (ok) {
          if (onSent) {
            // update the thread, it will remove the dummy messages and
            // render pictures downloaded from cache, timeout is needed in order to give
            // the cache a bit of time to populate, because the cache is async
            setTimeout(() => onSent(null), 2000);
          }

          return;
        }

        setMessages((prev) => {
          const found = prev.find((m) => m.SK === ts);
          if (found) {
            found.error = "upload error";
            return [...prev];
          }
          return prev;
        });

        toast.error(<UploadErrorToast item={item} onRetry={attemptUpload} />);
      };

      setMessages((prev) => (prev ? [...prev, messageObj] : [messageObj]));

      attemptUpload();
      setMessage("");
      setDisabled(true);
      setSelectedFiles([]);
      setPassword("");
    }; // proceed

    proceed();
  }; // handleSend

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    } else if (e.key === "ArrowUp") {
      if (!message) {
        setMessage((prev) => {
          if (!prev) {
            return previousMessage;
          } else {
            return prev;
          }
        });
        e.preventDefault();
      }
    }
  }; // handleKeyDown

  const handleKeyUp = (e) => {}; // handleKey

  const processFiles = (files) => {
    if (!files.length) {
      return;
    }

    const isDuplicate = (file) => {
      for (let f of selectedFiles) {
        if (
          file.name === f.name &&
          file.size === f.size &&
          file.type === f.type
        ) {
          return true;
        }
      }
      return false;
    };

    let newFiles = [];
    for (let fileToUpload of files) {
      if (!isDuplicate(fileToUpload)) {
        if (validateNotifyFileSize(fileToUpload)) {
          newFiles.push(fileToUpload);
        }
      }
    }
    if (newFiles.length > 0) {
      setSelectedFiles([...selectedFiles, ...newFiles]);
    }
  }; // processFiles

  const handleFileSelected = (e) => {
    processFiles(e.target.files);

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleDelete = (index) => {
    let trimmed = [...selectedFiles];
    trimmed.splice(index, 1);
    setSelectedFiles(trimmed);
    if (onFileRemoved) {
      onFileRemoved();
    }
  };

  const handleBlur = () => {
    // give a chance to other events to be handled
    // like when you click on a suggestion, that will immediately trigger blur
    // and you'll loose an event containing the clicked suggestion
    setTimeout(() => {
      if (!password) {
        saveMessageInCache(item, message);
      }
      if (onBlur) {
        onBlur();
      }
    }, 300);
  };

  const handleFocus = () => {
    if (onFocus) {
      onFocus();
    }
  };

  const handlePaste = (e) => {
    handlePasteEvent(e, processFiles, setRefs, (text) => {
      setMessage((prev) => (prev + text).slice(0, MAX_MESSAGE_SIZE));
      setDisabled(
        message.length === 0 && text.length === 0 && selectedFiles.length === 0
      );
    });
  };

  const handleRequestPassword = (e) => {
    setAnchorEl((prev) => (prev ? null : e.currentTarget));
  };

  const handlePasswordCancel = (e) => {
    setAnchorEl(null);
  };

  const handleSetPassword = (password) => {
    setPassword(password);
    setAnchorEl(null);
    if (password) {
      clearMessageInCache(item);
    } else {
      saveMessageInCache(item, message);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      const observer = new IntersectionObserver(([entry]) => {
        setVisible(entry.isIntersecting);
      });
      observer.observe(inputRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  useEffect(() => {
    if (focused && inputRef.current) {
      inputRef.current.focus();
    }
  }, [visible]);

  useEffect(() => {
    if (droppedFiles && !password) {
      processFiles(droppedFiles);

      if (inputRef.current && visible) {
        inputRef.current.focus();
      }
    }
  }, [droppedFiles]);

  useEffect(() => {
    if (message || (selectedFiles && selectedFiles.length > 0)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [selectedFiles, message]);

  return (
    <Stack width="100%">
      <TextFieldWithSuggestions
        setMessage={setMessage}
        label="Want to add anything to the story?"
        width="100%"
        size="small"
        value={message}
        fullWidth
        multiline
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onPaste={handlePaste}
        variant="standard"
        id={anchorEl ? "color-picker-popper" : undefined}
        inputRef={inputRef}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {true && (
                <>
                  <input
                    type="file"
                    id={`file-upload-${item.SK}-${id}`}
                    hidden
                    onChange={handleFileSelected}
                    multiple
                    disabled={!!password}
                  />
                  <label htmlFor={`file-upload-${item.SK}-${id}`}>
                    <IconButton component="span" disabled={!!password}>
                      <AttachFileIcon />
                    </IconButton>
                  </label>
                </>
              )}
              <IconButton
                // edge="end"
                color={password ? undefined : "primary"}
                disabled={selectedFiles.length > 0}
                onClick={handleRequestPassword}
                sx={{ color: password ? "red" : undefined }}
              >
                <KeyIcon />
              </IconButton>
              <IconButton
                edge="end"
                color="primary"
                disabled={disabled || (!!password && selectedFiles.length > 0)}
                onClick={handleSend}
              >
                <SendIcon />
              </IconButton>
            </InputAdornment>
          ),
          sx: {
            alignItems: "flex-start",
          },
        }}
      />
      <Popper
        id={anchorEl ? "color-picker-popper" : undefined}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
      >
        <PasswordInput
          password={password}
          onPassword={handleSetPassword}
          onCancel={handlePasswordCancel}
          label={"Protect message with password"}
        />
      </Popper>
      <FileUploadPreview
        item={item}
        files={selectedFiles}
        onDelete={handleDelete}
      />
    </Stack>
  );
}
