import { argon2id } from "hash-wasm";

function b64ToBytes(base64) {
  const binString = atob(base64);
  return Uint8Array.from(binString, (m) => m.codePointAt(0));
}

function bytesToB64(bytes) {
  const binString = String.fromCodePoint(...bytes);
  return btoa(binString);
}

function randomValues(length) {
  const rnd = new Uint8Array(length);
  window.crypto.getRandomValues(rnd);
  return rnd;
}

async function hashPassword(pass, salt) {
  const hash = await argon2id({
    password: pass,
    salt,
    parallelism: 1,
    iterations: 256,
    memorySize: 4 * 1024,
    hashLength: 32,
    outputType: "binary",
  });

  return hash;
}

export const decrypt = async (pass, message) => {
  if (message.ver !== 1) {
    throw new Error("unsupported cipher");
  }

  const salt = b64ToBytes(message.salt);
  const hash = await hashPassword(pass, salt);

  const key = await window.crypto.subtle.importKey(
    "raw",
    hash.buffer,
    { name: "AES-GCM" },
    false,
    ["decrypt"]
  );
  const iv = b64ToBytes(message.iv);

  const cipher = b64ToBytes(message.cipher);
  const original = await window.crypto.subtle.decrypt(
    { name: "AES-GCM", iv: iv },
    key,
    cipher
  );

  const decoder = new TextDecoder();
  const text = decoder.decode(original);

  return text;
};

export const encrypt = async (pass, message) => {
  const salt = randomValues(32);
  const hash = await hashPassword(pass, salt);

  const iv = randomValues(12);
  const key = await window.crypto.subtle.importKey(
    "raw",
    hash.buffer,
    { name: "AES-GCM" },
    false,
    ["encrypt"]
  );

  const enc = new TextEncoder();
  const encoded = enc.encode(message);

  const cipher = await window.crypto.subtle.encrypt(
    { name: "AES-GCM", iv: iv },
    key,
    encoded
  );

  return {
    ver: 1,
    cipher: bytesToB64(new Uint8Array(cipher)),
    salt: bytesToB64(salt),
    iv: bytesToB64(iv),
  };
};
