import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Divider from "@mui/material/Divider";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import { InlineIcon } from "@iconify/react";
import Tooltip from "@mui/material/Tooltip";
import DownloadIcon from "@mui/icons-material/Download";
import Stack from "@mui/material/Stack";
import { Image, ButtonLink } from "./Image";
import { codeStyle } from "./TextPreview";

import { useAtomValue } from "jotai";

import * as emojiname from "emoji-name-map";

import { useState, useEffect, Fragment } from "react";
import { StoryPopup } from "./StoryCard";
import { StoryFastPreview } from "./StoryDetails";
import { TextPreview } from "./TextPreview";

import {
  projectPickerState,
  activeLabelsState,
  peopleDataState,
} from "./JotaiAtoms";

import * as u from "./utility";
import * as riki from "jsriki";

import { useNewTabText, useHttpPost } from "./hooks";

function CodeLine({ fontSize, st_pk, st_sk, children }) {
  const [open, setOpen] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(children);
    setOpen(false);
  };

  return (
    <Tooltip
      placement="right"
      leaveDelay={400}
      enterDelay={0}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      title={
        <Fragment>
          <InlineIcon
            icon="solar:copy-bold"
            color="grey"
            style={{ cursor: "pointer", fontSize: "18px", opacity: 0.5 }}
            onClick={handleCopy}
          />
        </Fragment>
      }
      componentsProps={{
        tooltip: {
          sx: { p: 0, backgroundColor: "transparent" },
        },
      }}
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [3, -15],
            },
          },
        ],
      }}
    >
      <Typography
        component="span"
        fontSize={fontSize}
        st_pk={st_pk}
        st_sk={st_sk}
        sx={{
          background: "#d0d0d0",
          color: "#352f2d",
          p: 0.35,
          borderRadius: "3px",
        }}
      >
        {children}
      </Typography>
    </Tooltip>
  );
}

function CodeBlockText({ text }) {
  const [displayText, setDisplayText, handleDoubleClick] = useNewTabText();
  useEffect(() => {
    setDisplayText(text);
  }, [text]);

  return <Box onClick={handleDoubleClick}>{displayText}</Box>;
}

function CodeBlock({ st_pk, st_sk, children, fontSize, item, index }) {
  return (
    <Box st_pk={st_pk} st_sk={st_sk}>
      <TextPreview
        data={children}
        fontSize={fontSize}
        file={{ mimetype: "text/plain", name: "code.txt", index: index }}
        item={item}
      />
    </Box>
  );
}

function CodeBlockOld({ st_pk, st_sk, children, fontSize }) {
  const [hover, setHover] = useState(false);
  const [anchor, setAnchor] = useState(null);

  return (
    <Box
      sx={codeStyle(fontSize)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => {
        setHover(false);
        setAnchor(null);
      }}
    >
      {hover && (
        <Box sx={{ position: "absolute", right: 0, zIndex: 5 }}>
          <IconButton
            variant="contained"
            size="small"
            onClick={(e) => {
              navigator.clipboard.writeText(children);
              setAnchor(e.currentTarget);
              setTimeout(() => {
                setAnchor(null);
              }, 1000);
            }}
          >
            <ContentCopyIcon fontSize="inherit" sx={{ color: "white" }} />
          </IconButton>
          <Popper
            open={anchor != null}
            anchorEl={anchor}
            placement="right-start"
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper>
                  <Typography fontSize={fontSize * 0.8} sx={{ p: 1 }}>
                    copied!
                  </Typography>
                </Paper>
              </Fade>
            )}
          </Popper>
        </Box>
      )}

      <code st_pk={st_pk} st_sk={st_sk}>
        <pre
          style={{
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            fontSize: fontSize * 0.85,
          }}
        >
          <CodeBlockText text={children} />
        </pre>
      </code>
    </Box>
  );
}

function unescapeSlackEntities(str) {
  return str
    .replaceAll("&amp;", "&")
    .replaceAll("&lt;", "<")
    .replaceAll("&gt;", ">");
}

function compactMarkdown(parsed, unescape = false, result = null) {
  let compacted = result || [];

  const pushRaw = (v) => {
    compacted.push(v);
  };

  const pushUnescaped = (v) => {
    if (typeof v.v === "string") {
      v.v = unescapeSlackEntities(v.v);
    }
    pushRaw(v);
  };

  const push = unescape ? pushUnescaped : pushRaw;

  for (let i = 0; i < parsed.length; ++i) {
    const cur = parsed[i];
    if (typeof cur.v === "object") {
      let nested = [];
      compacted.push({ t: cur.t, v: nested });
      compactMarkdown(cur.v, unescape, nested);
    } else if (i == 0) {
      push(parsed[i]);
    } else {
      const prev = compacted[compacted.length - 1];
      if (prev.t === "br" && cur.t === "br") {
        compacted[compacted.length - 1] = { t: "br2" };
      } else {
        push(cur);
      }
    }
  }

  return compacted;
}

export function parseMarkdown(text, item) {
  if ("st_pp" in item) {
    if (typeof item.st_pp === "string") {
      const res = compactMarkdown(JSON.parse(item.st_pp));
      return res;
    } else {
      return compactMarkdown(item.st_pp);
    }
  }

  let parsed = riki.parse(text);

  let res = [];
  let styles = [];
  let cur = res;
  for (let i = 0; i < parsed.length / 2; ++i) {
    const t = parsed[i * 2];
    const v = parsed[i * 2 + 1];
    if (t == "clb") {
      cur = [];
      res.push({ t: "cl", v: cur });
    } else if (t == "cle") {
      cur = res;
    } else if (t == "cbb") {
      cur = [];
      res.push({ t: "cb", v: cur });
    } else if (t == "cbe") {
      cur = res;
    } else if (t == "x") {
      styles.push(v);
    } else if (t == "se") {
      styles.pop();
    } else if (t == "q") {
      cur.push({ t: "txt", v: ">" });
    } else if (t == "q2") {
      cur.push({ t: "txt", v: ">>" });
    } else {
      if (styles.length) {
        cur.push({ t: t, v: v, s: styles.join("") });
      } else {
        cur.push({ t: t, v: v });
      }
    }
  }
  return compactMarkdown(res, true);
}

export function Markdown({ parsed, item, fontSize, start, sx }) {
  return (
    <>
      {parsed.map((chunk, i) => (
        <MarkdownChunk
          key={start + i}
          type={chunk.t}
          value={chunk.v}
          refs={chunk.r}
          textStyle={chunk.s}
          item={item}
          fontSize={fontSize}
          index={start + i}
          end={start + parsed.length}
          sx={sx}
        />
      ))}
    </>
  );
}

function MarkdownChunk({
  type,
  value,
  item,
  refs,
  textStyle,
  fontSize,
  index,
  end,
  sx,
}) {
  const projectName = useAtomValue(projectPickerState);
  const refStory = { st_pk: item.PK, st_sk: item.SK };

  const urlFromStoryKey = (key) => {
    return (
      window.location.origin +
      `/story/${projectName}/${key.split("-")[1]}${window.location.search}`
    );
  };

  const urlFromRef = (ref) => {
    const key = ref.slice(1, -1);
    let url =
      window.location.origin +
      `/story/${projectName}/${encodeURIComponent(key)}${
        window.location.search
      }`;

    const timeref = item.st_created_at || item.ts || item.event_ts;
    if (timeref) {
      url += `/${encodeURIComponent(timeref)}`;
    }

    return url;
  };

  const urlFromLink = (link, trim) => {
    const trimmed = trim ? link.slice(trim, -trim) : link;
    return trimmed.split("|")[0];
  };

  const annotationFromLink = (link, trim) => {
    const v = trim ? link.slice(trim, -trim) : link;
    const delim = v.indexOf("|");
    if (delim == -1) {
      return v;
    } else {
      const annotation = v.slice(delim + 1);
      return annotation.length ? annotation : v.slice(0, delim);
    }
  };

  const getCode = (value, wrap) => {
    if (typeof value === "string") {
      return value.slice(wrap, -wrap);
    } else {
      let res = "";
      for (let p of value) {
        if (p.t === "txt") {
          res += p.v;
        } else if (p.t === "lnk") {
          res += p.v.slice(1, -1);
        }
      }
      return res;
    }
  };

  const convertTextStyle = (textStyle) => {
    let sx = {};
    if (textStyle) {
      let done = false;
      if (textStyle.includes("_")) {
        sx.fontStyle = "italic";
        done = true;
      }
      if (textStyle.includes("*")) {
        sx.fontWeight = "bold";
        done = true;
      }
      if (textStyle.includes("~")) {
        sx.textDecoration = "line-through";
        done = true;
      }

      if (!done) {
        // previous format
        if (textStyle.includes("i")) {
          sx.fontStyle = "italic";
        }
        if (textStyle.includes("b")) {
          sx.fontWeight = "bold";
        }
        if (textStyle.includes("s")) {
          sx.textDecoration = "line-through";
        }
      }
    }

    return sx;
  };

  if (type === "txt") {
    // text
    return (
      <Typography
        {...refStory}
        component="span"
        fontSize={fontSize}
        style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
        sx={convertTextStyle(textStyle)}
      >
        {value}
      </Typography>
    );
  } else if (type === "stk") {
    // strikethrough -- deprecated
    return (
      <Typography
        component="span"
        fontSize={fontSize}
        sx={{ textDecoration: "line-through" }}
      >
        {value.slice(1, -1)}
      </Typography>
    );
  } else if (type === "it") {
    // italic -- deprecated
    return (
      <Typography
        component="span"
        fontSize={fontSize}
        sx={{ fontStyle: "italic" }}
      >
        {value.slice(1, -1)}
      </Typography>
    );
  } else if (type === "bld") {
    // bold -- deprecated
    return (
      <Typography
        component="span"
        fontSize={fontSize}
        sx={{ fontWeight: "bold" }}
      >
        {value.slice(1, -1)}
      </Typography>
    );
  } else if (type === "url") {
    // raw url
    return (
      <>
        <Link href={value} fontSize={fontSize} sx={sx}>
          {value}
        </Link>
        {refs && refs.length && (
          <sup style={{ fontSize: fontSize * 0.62 }}>
            <StoryReference
              url={urlFromRef(`[${refs[0]}]`)}
              storyId={`[${refs[0]}]`}
              sx={sx}
            />
          </sup>
        )}
      </>
    );
  } else if (type === "br") {
    // line break
    return <br />;
  } else if (type === "br2") {
    // a pair of line breaks
    return (
      <>
        <br />
        <div style={{ fontSize: "30%" }}>
          <br />
        </div>
      </>
    );
  } else if (type === "rk") {
    // reference key like STORY-123
    return (
      <StoryReference
        storyId={value}
        url={urlFromStoryKey(value)}
        fontSize={fontSize}
        sx={sx}
      />
    );
  } else if (type === "ref") {
    // reference enclosed in square bracket like [123]
    return (
      <StoryReference
        storyId={value}
        url={urlFromRef(value)}
        fontSize={fontSize}
        sx={sx}
      />
    );
  } else if (type === "lnk") {
    // link enclosed in angle brackets <...>
    return (
      <>
        <Link
          href={urlFromLink(value, 1)}
          sx={{
            ...convertTextStyle(textStyle),
            fontSize: fontSize,
            ...(sx || {}),
          }}
        >
          {annotationFromLink(value, 1)}
        </Link>
        {refs && refs.length && (
          <sup style={{ fontSize: fontSize * 0.62 }}>
            <StoryReference
              url={urlFromRef(`[${refs[0]}]`)}
              storyId={`[${refs[0]}]`}
              sx={sx}
            />
          </sup>
        )}
      </>
    );
  } else if (type === "fr") {
    // github's footnote reference
    return (
      <sup style={{ fontSize: fontSize * 0.62 }}>
        <Link href={`#${item.PK}-${item.SK}-footnote-${value}`} sx={sx}>
          [{value}]
        </Link>
      </sup>
    );
  } else if (type === "fd") {
    // github's footnote definition
    return (
      <>
        {value == "1" && <Divider />}
        <span id={`${item.PK}-${item.SK}-footnote-${value}`}>
          {value + ". "}
        </span>
      </>
    );
  } else if (type === "footnote") {
    // github's footnote
    return (
      <>
        <br />
        <br />
        <Markdown
          parsed={value}
          item={item}
          fontSize={fontSize}
          start={end}
          sx={sx}
        />
      </>
    );
  } else if (type === "lx") {
    // github's link
    return (
      <>
        <Link
          href={urlFromLink(value, 0)}
          sx={{ ...convertTextStyle(textStyle), ...(sx || {}) }}
          fontSize={fontSize}
        >
          {annotationFromLink(value, 0)}
        </Link>
        {refs && refs.length && (
          <sup style={{ fontSize: fontSize * 0.62 }}>
            <StoryReference
              url={urlFromRef(`[${refs[0]}]`)}
              storyId={`[${refs[0]}]`}
              sx={sx}
            />
          </sup>
        )}
      </>
    );
  } else if (type === "fx") {
    // github's file attachment
    return (
      <FileAttachments
        files={[
          { url: urlFromLink(value, 0), name: annotationFromLink(value, 0) },
        ]}
      />
    );
  } else if (type === "u") {
    // slack's username
    return (
      <Typography
        component="span"
        fontSize={fontSize}
        style={{ whiteSpace: "pre-wrap" }}
      >
        <span>
          @<UserName userId={value.slice(2, -1)} />
        </span>
      </Typography>
    );
  } else if (type === "c") {
    // slacks' channel name
    return (
      <Typography
        component="span"
        fontSize={fontSize}
        style={{ whiteSpace: "pre-wrap" }}
      >
        #<ChannelName channelId={value.slice(2, -1)} />
      </Typography>
    );
  } else if (type === "e") {
    // emoji like :smile:
    return (
      <Typography
        component="span"
        fontSize={fontSize * 1.25}
        style={{ whiteSpace: "pre-wrap", lineHeight: "0em" }}
      >
        {emojiname.get(value) || value}
      </Typography>
    );
  } else if (type === "b") {
    // slack's broadcast like @here
    return (
      <Typography
        component="span"
        fontSize={fontSize}
        style={{ whiteSpace: "pre-wrap" }}
      >
        @{value.slice(2, -1).split("|").slice(-1)}
      </Typography>
    );
  } else if (type === "s") {
    return (
      <Typography
        component="span"
        fontSize={fontSize}
        style={{ whiteSpace: "pre-wrap" }}
      >
        @{value.slice(0, -1).split("^").slice(-1)}
      </Typography>
    );
  } else if (type === "cl") {
    // codeline `code`
    return (
      <CodeLine {...refStory} fontSize={fontSize}>
        {getCode(value, 1)}
      </CodeLine>
    );
  } else if (type === "cx") {
    // github's codeline
    return (
      <CodeLine {...refStory} fontSize={fontSize}>
        {value}
      </CodeLine>
    );
  } else if (type === "cb") {
    // codeblock ```code block```
    return (
      <CodeBlock {...refStory} fontSize={fontSize} item={item} index={index}>
        {u.trimNewLine(getCode(value, 3))}
      </CodeBlock>
    );
  } else if (type === "cbx") {
    //github's code block
    return (
      <CodeBlock {...refStory} fontSize={fontSize} item={item} index={index}>
        {u.trimNewLine(value)}
      </CodeBlock>
    );
  } else if (type === "q") {
    // quote
    return (
      <Box
        sx={{
          borderLeft: 4,
          borderLeftColor: "dimgrey",
          pl: 1,
          ml: 0.2,
          mt: 0.5,
        }}
      >
        <Markdown
          parsed={value}
          item={item}
          fontSize={fontSize}
          start={end}
          sx={sx}
        />
      </Box>
    );
  } else if (type === "t") {
    // table
    return (
      <TableContainer>
        <Table size="small" sx={{ width: "auto" }}>
          <TableHead>
            <TableRow>
              {value[0].map((cell, i) => (
                <TableCell key={i}>
                  <Markdown
                    parsed={cell}
                    item={item}
                    fontSize={fontSize}
                    start={end}
                    sx={sx}
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody stripedRows>
            {value.slice(1).map((row, i) => (
              <TableRow key={i}>
                {row.map((cell, i) => (
                  <TableCell key={i}>
                    <Markdown
                      parsed={cell}
                      item={item}
                      fontSize={fontSize}
                      start={end}
                      sx={sx}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else if (type === "h") {
    return (
      <>
        <Typography
          component="span"
          fontSize={fontSize}
          style={{ whiteSpace: "pre-wrap" }}
        >
          {value}
          {refs && refs.length && (
            <sup style={{ fontSize: fontSize * 0.62 }}>
              <StoryReference
                url={urlFromRef(`[${refs[0]}]`)}
                story={`[${refs[0]}]`}
                sx={sx}
              />
            </sup>
          )}
        </Typography>
      </>
    );
  } else if (type == "img") {
    // github image
    return (
      <>
        <br />
        <Image
          image={{ url: urlFromLink(value, 0) }}
          item={item}
          index={index}
          name={annotationFromLink(value, 0)}
        />
        <br />
      </>
    );
  } else {
    return <div />;
  }
}

function StoryReference({ storyId, url, fontSize, sx }) {
  return (
    <StoryPopup
      storyMoniker={storyId}
      enterDelay={300}
      previewComponent={<StoryFastPreview />}
    >
      <Link href={url} sx={{ fontSize: fontSize, ...(sx || {}) }}>
        {storyId}
      </Link>
    </StoryPopup>
  );
}

export function UserName({ userId }) {
  const data = useAtomValue(peopleDataState);
  const [user, setUser] = useState(userId);

  useEffect(() => {
    if (data) {
      setUser((prev) => {
        for (let u of data) {
          if (prev === u.id || prev === `${u.app}#${u.id}`) {
            if ("name" in u) {
              return u.name;
            } else if ("email" in u) {
              return u.email;
            } else if ("username" in u) {
              return u.username;
            }
          }
        }

        return prev;
      });
    }
  }, [data]);

  return <>{user}</>;
}

function ChannelName({ channelId }) {
  const data = useAtomValue(activeLabelsState);
  const [channel, setChannel] = useState(channelId.split("|").slice(-1));

  useEffect(() => {
    if (data) {
      setChannel((prev) => {
        if (prev in data) {
          let out = data[channel].display || data[channel].name;
          if (out.startsWith("#")) {
            return out.slice(1);
          }
          return out;
        }
        return prev;
      });
    }
  }, [data]);

  return <>{channel}</>;
}

export function FileAttachments({ files }) {
  const httpPost = useHttpPost();

  const handleDownload = (file) => {
    const request = async () => {
      try {
        const path =
          "slackfile?url=" +
          encodeURIComponent(file.url) +
          "&filename=" +
          encodeURIComponent(file.name);
        const response = await httpPost(path, null);
        window.location.href = response.data.url;
      } catch (err) {
        window.location.href = file.url;
      } finally {
      }
    };
    request();
  };

  return (
    <Stack gap={1}>
      {files &&
        files.map((file, i) => (
          <Stack key={i} direction="row">
            <DownloadIcon />
            <ButtonLink text={file.name} onclick={() => handleDownload(file)} />
          </Stack>
        ))}
    </Stack>
  );
}
