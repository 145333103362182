import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import { useState, useEffect } from "react";

import { toast } from "react-toastify";
import * as riki from "jsriki";

import {
  storyFilterBoxState,
  projectPickerState,
  newStoriesState,
} from "./JotaiAtoms";
import { useAtomValue, useSetAtom } from "jotai";

import { FileUploadPreview } from "./FileUploadPreview";
import { StoryLabels } from "./StoryLabels";
import * as u from "./utility";
import {
  validateNotifyFileSize,
  handlePasteEvent,
  MAX_MESSAGE_SIZE,
  TextFieldWithSuggestions,
} from "./SendMessageBox";

import {
  useHttpPost,
  useHttpPostAndProject,
  useProjectCache,
  useUploadFile,
} from "./hooks";

function CreateErrorToast({ onRetry }) {
  return (
    <Stack direction={"row"} alignItems={"center"}>
      <Typography fontSize={12}>Failed to create a new story</Typography>
      <Button onClick={onRetry} size="small">
        RETRY
      </Button>
    </Stack>
  );
}

const makeFileKey = (file) => {
  return `${file.name}:${file.size}:${file.lastModified}:${file.type}`;
};

function findSolution(expr) {
  const solution = riki.find_solution(expr);
  if (solution && solution.length) {
    return solution;
  }

  let labels = [];
  const operands = Array.from(new Set(riki.extract_expression_operands(expr)));

  let done = false;
  for (const op of operands) {
    if (riki.evaluate_expression(expr, op)) {
      labels.push(op);
      done = true;
      break;
    }
  }

  for (let i = 0; i < operands.length - 1 && !done; ++i) {
    for (let j = i; j < operands.length && !done; ++j) {
      const op1 = operands[i];
      const op2 = operands[j];
      if (riki.evaluate_expression(expr, `${op1} ${op2}`)) {
        labels.push(op1);
        labels.push(op2);
        done = true;
        break;
      }
    }
  }

  if (!done && riki.evaluate_expression(expr, operands.join(" "))) {
    labels.push(...operands);
  }

  return labels;
}

export function WebStory() {
  const [input, setInput] = useState(false);
  const [desc, setDesc, updateCachedDesc] = useProjectCache("WebStory", "");
  const [chips, setChips] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [dragActive, setDragActive] = useState(false);
  const [refs, setRefs] = useState([]);

  const filters = useAtomValue(storyFilterBoxState);
  const setNewStories = useSetAtom(newStoriesState);
  const [httpPost, project] = useHttpPostAndProject();
  const uploadToS3 = useUploadFile();

  const getProjectFilters = () => {
    return filters[project] || [];
  };

  const resetChipsBasedOnCurrentSelection = () => {
    const filters = getProjectFilters();
    const expressions = filters.filter((e) => u.isLabelExpression(e));
    let labels = filters.filter((e) => !u.isLabelExpression(e));
    for (const expr of expressions) {
      const solution = findSolution(expr);
      labels.push(...solution);
    }

    setChips(labels);
  };

  useEffect(() => {
    if (filters) {
      resetChipsBasedOnCurrentSelection();
    }
  }, [filters]);

  const handleKey = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleCreate();
    } else if (e.key === "Escape") {
      cancelWebStory();
    }
  };

  const handleDescChange = (e) => {
    const value = e.target.value;
    const truncated = value.slice(0, MAX_MESSAGE_SIZE);
    setDesc(truncated);
  };

  const cancelWebStory = () => {
    setDesc("");
    setInput(false);

    resetChipsBasedOnCurrentSelection();

    setSelectedFiles([]);
    setUploadedFiles({});
    updateCachedDesc("");
  };

  const handleChange = (newChips) => {
    setChips(newChips);
  };

  const handleCreate = () => {
    const request = async (dummy) => {
      try {
        const uploaded = [];
        for (let i = 0; i < selectedFiles.length; ++i) {
          const f = selectedFiles[i];
          const key = makeFileKey(f);
          const uri =
            key in uploadedFiles
              ? uploadedFiles[key]
              : await uploadToS3(f, project);

          if (!uri) {
            return false;
          }

          uploaded.push({
            uri: uri,
            type: f.type || f.detected_mimetype || "",
            size: f.size,
            name: f.name,
          });
        }

        const response = await httpPost("stories", {
          new: true,
          desc: desc,
          label: chips,
          files: uploaded,
          refs: refs,
        });

        if (response.data.new.length) {
          let story = response.data.new[0];
          story["old_dummy_message"] = dummy;
          story.internal_update = true;

          setNewStories((prev) => [...prev, story]);

          // update the story in hopes to get the autodetected st_ui from images
          if (uploaded.length > 0) {
            setTimeout(() => {
              httpPost("stories", { stories: true, story: story.SK }).then(
                (response) => {
                  let story = response.data.stories[0];
                  story["old_dummy_message"] = dummy;
                  story.internal_update = true;
                  setNewStories((prev) => [...prev, story]);
                }
              );
            }, 2000);
          }
        }

        return true;
      } catch (err) {}

      return false;
    };

    if (!desc.length && !selectedFiles.length) {
      return;
    }

    const ts = u.getCurrentTimestamp();

    const dummy = {
      SK: ts,
      SK2: ts,
      st_desc: desc,
      st_labels: chips,
      files: selectedFiles,
      refs: refs,
      dummy: true,
      type: "topstorie_file_upload",
    };

    setNewStories((prev) => [...prev, dummy]);
    cancelWebStory();

    const attemptCreate = async () => {
      setNewStories((prev) => {
        let found = prev.findIndex((m) => dummy.SK === m.SK && m.dummy);
        if (found >= 0) {
          let out = [...prev];
          out[found] = { ...dummy, error: null };
          return out;
        }

        return [
          ...prev,
          {
            ...dummy,
            error: null,
          },
        ];
      });

      const ok = await request(dummy);
      if (ok) {
        return;
      }

      setNewStories((prev) => {
        let found = prev.findIndex((m) => dummy.SK === m.SK && m.dummy);
        if (found >= 0) {
          let out = [...prev];
          out[found] = {
            ...dummy,
            error: null,
            old_dummy_message: { SK: dummy.SK },
          };
          return out;
        }

        return [
          ...prev,
          {
            ...dummy,
            old_dummy_message: { SK: dummy.SK },
            error: "create error",
          },
        ];
      });

      toast.error(<CreateErrorToast onRetry={attemptCreate} />);
    };

    attemptCreate();
  };

  const addFilesToStory = (files) => {
    if (!files.length) {
      return;
    }

    const isDuplicate = (file) => {
      for (let f of selectedFiles) {
        if (makeFileKey(file) === makeFileKey(f)) {
          return true;
        }
      }
      return false;
    };

    let newFiles = [];
    for (let fileToUpload of files) {
      if (!isDuplicate(fileToUpload)) {
        if (validateNotifyFileSize(fileToUpload)) {
          newFiles.push(fileToUpload);
        }
      }
    }

    const silentUpload = async (newFiles) => {
      let newUploaded = { ...uploadedFiles };
      for (const f of newFiles) {
        // this may fail, but we do not do any error handling
        const cacheKey = await uploadToS3(f);
        const fileKey = makeFileKey(f);
        if (cacheKey) {
          newUploaded[fileKey] = cacheKey;
        }
      }
      setUploadedFiles((prev) => {
        return { ...prev, ...newUploaded };
      });
    };

    if (newFiles.length > 0) {
      setSelectedFiles([...selectedFiles, ...newFiles]);
      silentUpload(newFiles);
    }
  };

  const handleFileSelected = (e) => {
    addFilesToStory(e.target.files);
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    addFilesToStory(e.dataTransfer.files);
    setInput(true);
  };

  const handleDelete = (index) => {
    let trimmed = [...selectedFiles];
    trimmed.splice(index, 1);
    setSelectedFiles(trimmed);
  };

  const handleBlur = () => {
    updateCachedDesc(desc);
  };

  const handlePaste = (e) => {
    handlePasteEvent(e, addFilesToStory, setRefs);
  };

  return (
    <Box
      sx={{
        mt: 0.3,
        backgroundColor: dragActive ? "aliceblue" : undefined,
        borderRadius: 3,
      }}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
    >
      <Stack direction="row" gap={1} alignItems={input ? "top" : "center"}>
        {!input && (
          <>
            <IconButton
              onClick={() => {
                setInput(true);
              }}
            >
              <AddBoxIcon color="success" />
            </IconButton>
            <Box onClick={() => setInput(true)}>
              <Typography fontSize={11} color="silver">
                Click here to begin a new exciting story ...
              </Typography>
            </Box>
          </>
        )}
        {input && (
          <>
            <Stack sx={{ width: "60%", mt: 2 }} direction={"column"}>
              <TextFieldWithSuggestions
                setMessage={setDesc}
                fullWidth
                multiline
                variant="standard"
                size="small"
                autoFocus
                value={desc}
                placeholder="Tell us more ..."
                onBlur={handleBlur}
                onKeyDown={handleKey}
                onChange={handleDescChange}
                onPaste={handlePaste}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {true && (
                        <>
                          <input
                            type="file"
                            id={`new-web-story-file-upload`}
                            hidden
                            onChange={handleFileSelected}
                            multiple
                          />
                          <label htmlFor={`new-web-story-file-upload`}>
                            <IconButton component="span">
                              <AttachFileIcon />
                            </IconButton>
                          </label>
                        </>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <FileUploadPreview
                files={selectedFiles}
                onDelete={handleDelete}
              />
              <Stack direction={"row"}>
                <Button
                  disabled={!desc.trim().length && !selectedFiles.length}
                  variant="text"
                  size="small"
                  onClick={handleCreate}
                >
                  OK
                </Button>
                <Button
                  variant="text"
                  size="small"
                  onClick={() => cancelWebStory()}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
            <Stack
              direction={"row-reverse"}
              width="100%"
              justifyContent={"flex-end"}
              sx={{ mt: 2 }}
            >
              <StoryLabels
                labels={chips}
                setLabels={setChips}
                onLabelCreated={handleChange}
              />
            </Stack>
          </>
        )}
      </Stack>
    </Box>
  );
}
