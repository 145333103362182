import { atom } from "jotai";

import * as u from "./utility";

import store from "store2";

export const imageToggleAtom = atom(
  u.readLocalStorage("ImageToggle") !== "false"
);
export const storyFilterBoxState = atom(null);

export const projectPickerState = atom(
  u.projectFromLocation() ||
    (window.location.pathname === "/"
      ? store.get("ProjectPicker:" + window.location.search, "")
      : "")
);

export const timeFilterAtom = atom(null);

export const timeFilterState = atom(
  (get) => {
    const prev = get(timeFilterAtom);
    const proj = get(projectPickerState);
    const key = u.generateCacheKey("TimeFilter", proj);
    const cached = store.get(key, "all time");

    return prev !== cached ? cached : prev;
  },
  (get, set, newValue) => {
    const proj = get(projectPickerState);
    const key = u.generateCacheKey("TimeFilter", proj);
    store(key, newValue);
    set(timeFilterAtom, newValue);
  }
);

export const debugToggleState = atom(
  u.readLocalStorage("DebugToggle") !== "false"
);

export const activeLabelsState = atom(null);

export const captionsState = atom(JSON.parse(store.get("Captions", "{}")));

export const peopleDataState = atom(null);

export const negateLabelsState = atom(
  u.readLocalStorage("NegateLabels", "") === "true"
);

export const labelToggleState = atom(
  u.readLocalStorage("LabelToggle") !== "false"
);

export const searchState = atom("");

export const newStoriesState = atom([]);

export const mergedStoriesState = atom([]);

export const joinStoriesState = atom([]);

export const reloadState = atom(0);

export const showSelectedStoriesState = atom("false");

export const selectedCategory = atom("");
