import { useEffect, useState, useRef } from "react";

import {
  captionsState,
  searchState,
  projectPickerState,
  selectedCategory,
} from "./JotaiAtoms";

import { useAtomValue, useSetAtom } from "jotai";
import { AutocompleteStoryInput } from "./AutocompleteStory";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { useProjectCaptions } from "./hooks";
import { Suggestions } from "./SendMessageBox";
import * as lodash from "lodash";

export function Search({ onSearch }) {
  const setSearch = useSetAtom(searchState);
  const captions = useProjectCaptions();

  const doSearch = (query) => {
    if (!query) {
      return;
    }

    if (onSearch) {
      onSearch(query.trim());
    }
    setSearch(query.trim());
  };

  const handleSelected = (val) => {
    doSearch(val);
  };

  return (
    <AutocompleteStoryInput
      placeholder="Search"
      onSelected={handleSelected}
      variant={"standard"}
      options={captions}
    ></AutocompleteStoryInput>
  );
}

export function QuickSearch() {
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState("");
  const captions = useProjectCaptions();
  const suggestionsRef = useRef();
  const setSearch = useSetAtom(searchState);
  const setCategory = useSetAtom(selectedCategory);
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "/") {
        const node = e.target.nodeName;
        if (node !== "INPUT" && node != "TEXTAREA") {
          setOpen(true);
          e.preventDefault();
        }
      }
    });
  }, []);

  const handleChange = (e) => {
    setInput(e.target.value);
    setSelected(0);
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  const handleSelected = (value) => {
    setSearch(value);
    setCategory("Stories");
    setInput("");
    setOpen(false);
  };

  const selectPrev = () => {
    if (suggestionsRef.current && suggestionsRef.current.length) {
      const index = Math.max(0, selected - 1);
      setSelected(index);
    }
  };

  const selectNext = () => {
    if (suggestionsRef.current && suggestionsRef.current.length > 0) {
      const index = Math.min(suggestionsRef.current.length - 1, selected + 1);
      setSelected(index);
    }
  };

  const handleKey = (e) => {
    if (e.key === "Enter") {
      handleSelected(input);
    } else if (e.key === "Tab") {
      e.preventDefault();

      let suggestion = input;
      if (suggestionsRef.current && suggestionsRef.current.length > 0) {
        const index = lodash.clamp(
          selected,
          0,
          suggestionsRef.current.length - 1
        );
        suggestion = suggestionsRef.current[index].text;
      }

      handleSelected(suggestion);
    } else if (e.key === "ArrowUp" || (e.ctrlKey && e.key === "k")) {
      selectPrev();
    } else if (e.key === "ArrowDown" || (e.ctrlKey && e.key === "j")) {
      selectNext();
    }
  };

  return (
    <>
      {open && (
        <Dialog
          onClose={handleClose}
          open={open}
          scroll="paper"
          sx={{
            "& .MuiDialog-container": {
              alignItems: "flex-start",
            },
          }}
          PaperProps={{ sx: { mt: "100px" } }}
        >
          <Stack
            sx={{ width: 600, mt: 0, minHeight: 34.5 }}
            direction={"column"}
          >
            <TextField
              onChange={handleChange}
              value={input}
              variant="outlined"
              onKeyDown={handleKey}
              sx={{
                fontSize: 9,
                "& .MuiOutlinedInput-root": {
                  "&.MuiInputBase-root fieldset": {
                    borderColor: "white",
                  },
                },
              }}
              size="small"
              autoFocus
              // inputRef={inputRef}
              InputProps={{
                style: { fontSize: 12 },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            ></TextField>
            <Box sx={{ mt: -0.5 }}>
              <Suggestions
                input={input}
                captions={captions}
                onSelected={handleSelected}
                suggestionsRef={suggestionsRef}
                sx={{ borderRadius: 0 }}
                selectedIndex={selected}
              />
            </Box>
          </Stack>
        </Dialog>
      )}
    </>
  );
}
