import { useRef, useState, useEffect } from "react";

import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import { Icon } from "@iconify/react";
import Switch from "@mui/material/Switch";
import { red, green } from "@mui/material/colors";
import { alpha, styled } from "@mui/material/styles";
import * as lodash from "lodash";
import Tooltip from "@mui/material/Tooltip";
import store from "store2";
import * as riki from "jsriki";

import {
  negateLabelsState,
  activeLabelsState,
  reloadState,
} from "./JotaiAtoms";

import { storyFilterBoxState, projectPickerState } from "./JotaiAtoms";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useHttpPostAndProject } from "./hooks";

import * as u from "./utility";

import { AutocompleteButton } from "./AutocompleteButton";
import log from "./logger";

function isValidExpression(text, labelLookup) {
  const trimmed = text.trim();
  if (trimmed) {
    let valid = riki.validate_expression(trimmed);
    if (valid) {
      const ops = riki.extract_expression_operands(trimmed);
      for (let op of ops) {
        if (!labelLookup.has(op)) {
          const norm = normalizeCompositeLabel(op);
          if (norm === op || !labelLookup.has(norm)) {
            valid = false;
            break;
          }
        }
      }
    }
    return valid;
  } else {
    return true;
  }
}

function substituteExpression(expression, labels) {
  let origExpr = normalizeExpression(expression.trim());
  let normExpr = origExpr;

  // replace display names with actual names
  const plainLabels = labels.filter((e) => !e.expression);
  for (const label of plainLabels) {
    if (typeof label.display === "string" && label.display !== label.name) {
      normExpr = normExpr.replace(
        " " + label.display + " ",
        " " + label.name + " "
      );
    }
  }

  // reqursively expand queries, with max depth
  let queries = labels.filter((e) => e.expression);
  let placeholders = [];
  for (let i = 0; i < 3 && queries.length > 0; ++i) {
    const numPlaceholders = placeholders.length;
    for (const label of queries) {
      const placeholder = `$${placeholders.length}`;
      const subst = normalizeExpression(
        `(${placeholder} or (${label.expression})) `
      );
      const replaced = normExpr.replace(` ${label.name} `, subst);
      if (replaced !== normExpr) {
        normExpr = replaced;
        placeholders.push(label.name);
      }
    }

    if (placeholders.length === numPlaceholders) {
      break;
    }
  }

  for (let i = 0; i < placeholders.length; ++i) {
    normExpr = normExpr.replace(` $${i} `, ` ${placeholders[i]} `);
  }

  return normExpr == origExpr ? expression : normExpr;
}

const ColoredSwitch = styled(Switch, {
  shouldForwardProp: (prop) => prop !== "color" && prop !== "colorChecked",
})(({ theme, color, colorChecked }) => {
  const checked = colorChecked
    ? {
        "& .MuiSwitch-switchBase.Mui-checked": {
          color: colorChecked,
          "&:hover": {
            backgroundColor: alpha(
              colorChecked,
              theme.palette.action.hoverOpacity
            ),
          },
        },
        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
          backgroundColor: colorChecked,
        },
      }
    : {};

  const unchecked = color
    ? {
        "& .MuiSwitch-switchBase": {
          color: color,
          "&:hover": {
            backgroundColor: alpha(color, theme.palette.action.hoverOpacity),
          },
        },
        // "& .MuiSwitch-switchBase + .MuiSwitch-track": {
        //   backgroundColor: color,
        // },
      }
    : {};

  return { ...checked, ...unchecked };
});

export function LabelsData() {
  const reloadData = useAtomValue(reloadState);
  const setActiveLabels = useSetAtom(activeLabelsState);
  const [httpPost, project] = useHttpPostAndProject();

  const getData = async (project) => {
    try {
      const response = await httpPost("stories", {
        activelabels: true,
        project: project,
      });

      const newLabels = response.data.activelabels;

      let mapping = {};
      mapping[":white_check_mark:"] = {
        name: ":white_check_mark:",
        display: { icon: "material-symbols:check-box-rounded" },
      };
      mapping["github"] = { name: "github", display: { icon: "uil:github" } };
      mapping["slack"] = { name: "slack", display: { icon: "uil:slack" } };
      mapping["gitlab"] = {
        name: "gitlab",
        display: { icon: "ph:gitlab-logo-fill" },
      };

      const channels = newLabels.channels;
      const objects = newLabels.objects;

      const combined = [...newLabels.labels, ...newLabels.queries].sort(
        (a, b) => (a.calls || 0) < (b.calls || 0)
      );

      for (let i = 0; i < combined.length; ++i) {
        const l = combined[i];
        const name = l.name;

        if (l.query) {
          if (!mapping[name]) {
            mapping[name] = {
              name: name,
              display: name,
              expression: l.query,
              index: i,
            };
          }
        } else {
          let displayName = name;
          if (channels && channels[name]) {
            displayName = `#${channels[name].name_normalized}`;
          } else if (objects && objects[name]) {
            displayName = objects[name].full_name;
          }

          const o = mapping[name] || { name: name, display: displayName };
          o.index = i;
          mapping[name] = o;
        }
      }
      setActiveLabels((prev) => {
        for (let [k] of Object.entries(mapping)) {
          if (!prev || !(k in prev)) {
            return mapping;
          }
        }
        return prev;
      });
    } catch (err) {
      log.debug(err);
    } finally {
    }
  };

  useEffect(() => {
    const proj = project;
    if (proj) {
      getData(proj);
    }
  }, [reloadData, project]);
}

export const normalizeCompositeLabel = (label) => {
  if (!label) {
    return label;
  }

  let slash = true;
  let res = "";
  for (let ch of label) {
    if (slash && ch !== "/") {
      slash = false;
      res += ch;
    } else if (!slash && ch === "/") {
      slash = true;
      res += ch;
    } else if (!slash) {
      res += ch;
    }
  }
  if (res && res[res.length - 1] === "/") {
    res = res.slice(0, res.length - 1);
  }

  return res;
};

const normalizeExpression = (expr) => {
  return (
    " " +
    expr.replace(/\s+/g, " ").replace("(", " ( ").replace(")", " ) ") +
    " "
  );
};

export function StoryLabelFilterBox({ newLabel }) {
  const [labels, setLabels] = useState(null);
  const project = useAtomValue(projectPickerState);
  const [negate, setNegate] = useAtom(negateLabelsState);
  const ref = useRef({});
  const activeLabels = useAtomValue(activeLabelsState);
  const [validExpression, setValidExpression] = useState(true);
  const [expressionMembers, setExpressionMembers] = useState();
  const [labelLookup, setLabelLookup] = useState(new Set());

  const [globalFilters, setGlobalFilters] = useAtom(storyFilterBoxState);
  const [selected, setSelected] = useState();

  const updateSelection = (selection) => {
    if (project) {
      const key = u.generateCacheKey("StoryLabelFilterBox", project);
      store(key, JSON.stringify(selection));
    }
    setSelected(selection);
  };

  useEffect(() => {
    if (project) {
      const key = u.generateCacheKey("StoryLabelFilterBox", project);
      let fromCache = JSON.parse(store.get(key, "[]"));
      if (!lodash.isArray(fromCache)) {
        fromCache = [];
      }
      setSelected((prev) => {
        if (!prev || !lodash.isEqual(fromCache, prev)) {
          return fromCache;
        }
        return prev;
      });
    }
  }, [project]);

  useEffect(() => {
    if (project && globalFilters && globalFilters[project]) {
      let uniq = new Set();
      for (let e of globalFilters[project]) {
        if (u.isLabelExpression(e)) {
          for (let op of riki.extract_expression_operands(e)) {
            uniq.add(op);
            if (op.includes("/")) {
              const norm = normalizeCompositeLabel(op);
              uniq.add(norm);
            }
          }
        }
      }

      if (uniq.size > 0) {
        setExpressionMembers(uniq);
        return;
      }
    }

    setExpressionMembers(undefined);
  }, [project, globalFilters]);

  useEffect(() => {
    if (project && selected && labels) {
      const substituted = selected.map((e) => substituteExpression(e, labels));

      setGlobalFilters((prev) => {
        if (prev) {
          if (!lodash.isEqual(prev[project], substituted)) {
            let next = { ...prev };
            next[project] = substituted;
            return next;
          }
          return prev;
        } else {
          let next = {};
          next[project] = substituted;
          return next;
        }
      });
    }
  }, [project, selected, labels]);

  // labels created from ui
  useEffect(() => {
    setLabels((prev) => {
      if (newLabel && newLabel.length) {
        let result = prev ? [...prev] : [];
        const names = prev.map((x) => x.name);
        for (let l of newLabel) {
          if (!names.includes(l)) {
            result.push({ name: l, display: l, user_created: true });
          }
        }
        return result;
      }
      return prev;
    });
  }, [newLabel, setLabels]);

  useEffect(() => {
    if (!activeLabels || !selected) {
      return;
    }

    let mapping = { ...activeLabels };
    let listing = [];

    for (let [k, v] of Object.entries(mapping)) {
      if (v.index >= 0) {
        listing.push(v);
      }
    }
    listing.sort((a, b) => a.index - b.index);

    // add any new labels that were created in the ui previously and are missing from backend
    if (labels) {
      for (let l of labels) {
        if (l.user_created && !mapping[l.name]) {
          listing.push(l);
          mapping[l.name] = l;
        }
      }
    }
    setLabels((prev) => {
      return lodash.isEqual(prev, listing) ? prev : listing;
    });

    let newSelected = [];
    let prevSelected = selected;
    for (let s of prevSelected) {
      if (u.isLabelExpression(s) || mapping[s]) {
        newSelected.push(s);
      }
    }
    if (!u.equalArrays(newSelected, prevSelected)) {
      updateSelection(newSelected);
    }

    let lookup = new Set();
    const addLookupLabel = (name) => {
      lookup.add(name);
      if (name.includes("/")) {
        const norm = normalizeCompositeLabel(name);
        lookup.add(norm);

        const parts = norm.split("/");
        for (let i = 1; i < parts.length; ++i) {
          const prefix = parts.slice(0, i).join("/");
          lookup.add(prefix);
        }
      }
    };

    for (let [k, v] of Object.entries(mapping)) {
      addLookupLabel(k);
      if (typeof v.display === "string") {
        addLookupLabel(v.display);
      }
    }
    setLabelLookup((prev) => (lodash.isElement(lookup, prev) ? prev : lookup));
  }, [activeLabels, selected]);

  const replaceSelectedFilter = (selected, labels) => {
    if (u.equalArrays(selected, labels)) {
      updateSelection([]);
    } else {
      updateSelection(labels);
    }
  };

  const addLabelToSelection = (selected, labels) => {
    const newFilters = labels
      .filter((e) => !selected.includes(e))
      .concat(selected.filter((e) => !labels.includes(e)));
    updateSelection(newFilters);
  };

  const handleClick = (e, labelsClicked) => {
    if (labelsClicked.length === 0) {
      return;
    }

    // upate selected labels to be displayed on UI
    if (e.metaKey || e.shiftKey || e.ctrlKey) {
      addLabelToSelection(selected, labelsClicked);
    } else {
      replaceSelectedFilter(selected, labelsClicked);
    }

    // open in new tab when double clicked
    if (ref.current.prevClick) {
      const elapsed = e.timeStamp - ref.current.prevClick;
      if (e.detail > 1 && elapsed < 200 && project) {
        const url = u.getStoryUrlFromId(labelsClicked[0], project);
        window.open(url, "_blank", "noreferrer");
      }
    }
    ref.current.prevClick = e.timeStamp;
  };

  const handleClearSelection = () => {
    updateSelection([]);
  };

  const handleLabelSearch = (value, e) => {
    if (!value) {
      return;
    }

    const trimmedValue = value.trim();
    if (!u.isLabelExpression(trimmedValue)) {
      const norm = normalizeCompositeLabel(trimmedValue);

      const exactMatch = (e) => {
        return (
          (typeof e.display === "string" &&
            (e.display === trimmedValue ||
              normalizeCompositeLabel(e.display) === norm)) ||
          (e.name &&
            (e.name === trimmedValue ||
              normalizeCompositeLabel(e.name) === norm))
        );
      };

      const compositeMatch = (e) => {
        return (
          (typeof e.display === "string" &&
            normalizeCompositeLabel(e.display).startsWith(`${norm}/`)) ||
          (e.name && normalizeCompositeLabel(e.name).startsWith(`${norm}/`))
        );
      };

      const all = labels.filter((e) => exactMatch(e) || compositeMatch(e));
      const exact = labels.find(
        (e) => e.display === trimmedValue || e.value === trimmedValue
      );
      const found = exact
        ? [exact].concat(all.filter((e) => e.name !== exact.name))
        : all;

      handleClick(
        e,
        found.map((e) => e.name)
      );
    } else {
      if (riki.validate_expression(trimmedValue)) {
        handleClick(e, [trimmedValue]);
      }
    }
    setValidExpression(true);
  };

  const handleSearchInput = (text) => {
    setValidExpression(isValidExpression(text, labelLookup));
  };

  const handleNegate = () => {
    u.writeLocalStorage("NegateLabels", negate ? "" : "true");
    setNegate((prev) => !prev);
  };

  const LABEL_SELECTED_COLOR = "green";
  const LABEL_NEGATIVE_COLOR = "tomato";
  const EXPR_SELECTED_COLOR = "#adc7b4";
  const EXPR_NEGATIVE_COLOR = "#c4afb3";

  const getBackgroundColor = (label) => {
    if (selected.includes(label)) {
      return negate ? LABEL_NEGATIVE_COLOR : LABEL_SELECTED_COLOR;
    } else if (expressionMembers) {
      const positive = EXPR_SELECTED_COLOR;
      const negative = EXPR_NEGATIVE_COLOR;
      if (expressionMembers.has(label)) {
        return negate ? negative : positive;
      } else if (label.includes("/")) {
        const parts = normalizeCompositeLabel(label).split("/");
        for (let i = 1; i < parts.length; ++i) {
          const prefix = parts.slice(0, i).join("/");
          if (expressionMembers.has(prefix)) {
            return negate ? negative : positive;
          }
        }
      }
    }

    return "#eeeeee";
  };

  const getColor = (label) => {
    return selected.includes(label) ? "white" : "black";
  };

  const getIconColor = (label) => {
    if (selected.includes(label)) {
      return negate ? LABEL_NEGATIVE_COLOR : LABEL_SELECTED_COLOR;
    } else if (expressionMembers && expressionMembers.has(label)) {
      return negate ? EXPR_NEGATIVE_COLOR : EXPR_SELECTED_COLOR;
    }

    return "grey";
  };

  const anyFiltersSelected = () => {
    return selected && selected.length > 0;
  };

  return (
    <Grid container spacing={0} sx={{ m: 0.5 }}>
      <Grid item>
        <ColoredSwitch
          colorChecked={red[600]}
          color={anyFiltersSelected() ? green[400] : undefined}
          onChange={handleNegate}
          checked={negate}
          inputProps={{ "aria-label": "Negate switch" }}
          size="small"
        />
      </Grid>
      <Grid item>
        <Tooltip
          title="Clear selection"
          placement="top-end"
          arrow
          enterDelay={500}
        >
          <span>
            <IconButton
              size="small"
              disabled={!anyFiltersSelected()}
              onClick={handleClearSelection}
              style={{ color: anyFiltersSelected() ? "lightpink" : undefined }}
            >
              <CancelIcon fontSize="inherit" />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
      {labels && (
        <Grid item>
          <AutocompleteButton
            variant="standard"
            options={[
              ...new Set(
                labels.map((e) =>
                  typeof e.display === "string" ? e.display : e.name
                )
              ),
            ]} // remove duplicates from there otherwise i'll get a key uniqueness error
            onSelected={handleLabelSearch}
            onTextInput={handleSearchInput}
            sx={validExpression ? undefined : { backgroundColor: "#fff0f0" }}
          >
            <SearchIcon fontSize="inherit" />
          </AutocompleteButton>
        </Grid>
      )}
      {labels &&
        labels.map((item, index) => (
          <Grid item key={item.name}>
            {typeof item.display === "string" && (
              <Chip
                size="small"
                style={{
                  background: getBackgroundColor(item.name),
                  color: getColor(item.name),
                  // maxWidth: 150
                }}
                label={item.display}
                onClick={(e) => {
                  handleLabelSearch(item.name, e);
                }}
              />
            )}
            {typeof item.display === "object" && (
              <IconButton
                style={{ color: getIconColor(item.name) }}
                size="small"
                onClick={(e) => {
                  handleClick(e, [item.name]);
                }}
              >
                <Icon icon={item.display.icon} />
              </IconButton>
            )}
          </Grid>
        ))}
    </Grid>
  );
}
