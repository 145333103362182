import * as u from "./utility";

export const getSourceUrl = (item) => {
  if (item.st_url) {
    return item.st_url;
  }

  if (isGithub(item)) {
    if ("commits" in item) {
      return item.commits[item.commits.length - 1].url;
    } else if ("pull_request" in item) {
      return item.pull_request.html_url;
    } else if ("comment" in item) {
      return item.comment.html_url;
    } else if ("ref_type" in item && item["ref_type"] === "branch") {
      return item.repository.html_url + "/tree/" + item.ref;
    } else if ("issue" in item) {
      return item.issue.html_url;
    }
  } else if (isGitlab(item)) {
    if (item.commits && item.commits.length > 0) {
      const c = item.commits[item.commits.length - 1];
      return c.url || c.web_url;
    } else if (item.merge_request) {
      return item.merge_request.url;
    } else if (item.object_attributes) {
      return item.object_attributes.url;
    }
  }

  return null;
};

export const isGithub = (item) => {
  if ("st_source" in item && item.st_source === "github") {
    return true;
  }
  if ("repository" in item && "html_url" in item.repository) {
    return true;
  }

  return false;
};

export const isGitlab = (item) => {
  if (item.st_source === "gitlab") {
    return true;
  }

  if (
    item.repository &&
    item.repository.homepage &&
    item.repository.homepage.includes("gitlab")
  ) {
    return true;
  }

  if (
    item.project &&
    item.project.web_url &&
    item.project.web_url.includes("gitlab")
  ) {
    return true;
  }

  return false;
};

function stringifyPreparsed(item) {
  let res = "";
  for (let i of item) {
    if (i.t == "br") {
      res += "\n";
    } else if (i.t == "q") {
      res += stringifyPreparsed(i.v);
    } else if (i.v && typeof i.v === "string") {
      res += i.v;
    }
  }
  return res;
}

export function rebuildMessageText(item) {
  const raw = getRawMessageText(item);
  if (raw.length > 0) {
    return raw;
  }

  if (item.st_pp) {
    const pp = JSON.parse(item.st_pp);
    let res = stringifyPreparsed(pp);
    return res;
  }

  return "";
}

export function getRawMessageText(item) {
  if ("st_desc" in item) {
    return item.st_desc;
  } else if ("text" in item && item.text) {
    return item.text;
  }

  if ("subtype" in item) {
    if (item.subtype === "message_changed") {
      if (item.message.subtype === "tombstone") {
        return (
          "Message deleted: " +
          ("previous_message" in item && "text" in item.previous_message
            ? item.previous_message.text
            : "")
        );
      }

      return item.message.text;
    } else if (item.subtype === "message_deleted") {
      if ("previous_message" in item) {
        if ("text" in item.previous_message && item.previous_message.text) {
          return `Message deleted: ${item.previous_message.text}`;
        } else if (
          "files" in item.previous_message &&
          item.previous_message.files.length > 0
        ) {
          return `File deleted: ${item.previous_message.files[0].name}`;
        }
      }
      return "Message deleted";
    }
  }

  if (isGithub(item)) {
    if ("commits" in item && item.commits.length) {
      const len = item.commits.length;
      const postfix = len === 2 ? "" : "s";
      const message = item.commits[len - 1].message;
      return message + (len > 1 ? ` (+${len - 1} other${postfix})` : "");
    }

    if ("pull_request" in item) {
      return `PR ${item.action}: ${item.pull_request.title} #${item.number}`;
    }

    if ("comment" in item && "pull_request" in item) {
      return `PR commented: ${item.comment.body}`;
    }

    if ("ref_type" in item) {
      return `${item.ref_type} ${item.ref}`;
    }

    if ("issue" in item) {
      if ("comment" in item) {
        return `commented: issue #${item.issue.number} ${item.comment.body}`;
      }

      if (item.action === "opened") {
        return `${item.action}: issue #${item.issue.number} ${item.issue.title}\n${item.issue.body}`;
      } else {
        return `${item.action}: issue #${item.issue.number} ${item.issue.title}`;
      }
    }
  } else if (isGitlab(item)) {
    if ("commits" in item && item.commits.length) {
      const len = item.commits.length;
      const postfix = len === 2 ? "" : "s";
      const message = item.commits[len - 1].title;
      return message + (len > 1 ? ` (+${len - 1} other${postfix})` : "");
    }

    if ("object_kind" in item) {
      if (item.object_kind === "merge_request") {
        const action = item.object_attributes.action;
        const title = item.object_attributes.title;
        const iid = item.object_attributes.iid;
        return `MR ${action}: ${title} !${iid}`;
      } else if (item.object_kind === "note") {
        if ("merge_request" in item) {
          return `MR commented: ${item.object_attributes.note}`;
        } else {
          return `commented: ${item.object_attributes.note}`;
        }
      } else if (item.object_kind === "issue") {
        const iid = item.object_attributes.iid;
        const title = item.object_attributes.title;
        const desc = item.object_attributes.description;
        if (item.object_attributes.action === "open") {
          return `issue #${iid}: ${title}\n${desc}`;
        } else {
          return `${item.object_attributes.action} issue #${iid}`;
        }
      }
    }
  }

  return "";
}

export const getShortDescription = (item) => {
  let res = "";
  if ("SK4" in item) {
    res += u.getStoryKey(item) + " ";
  }
  res += rebuildMessageText(item);
  return res;
};

export const isPinned = (item) => {
  return item.SK2.startsWith("pin:");
};

export const hasNotification = (item) => {
  return item.st_ui && item.st_ui.notif && item.st_ui.notif.length > 0;
};

export const hasNotificationToday = (item) => {
  if (hasNotification(item)) {
    for (let notif_date of item.st_ui.notif) {
      const date = u.parsePythonTimestamp(notif_date);
      if (u.isTodayDate(date)) {
        return date;
      }
    }
  }
  return null;
};

const hasFutureNotification = (item) => {
  const today = u.todayRange();
  if (hasNotification(item)) {
    for (let notif_date of item.st_ui.notif) {
      const date = u.parsePythonTimestamp(notif_date);
      if (date > today[1]) {
        return date;
      }
    }
  }
  return null;
};

export function getTimestamp(item) {
  if (isGithub(item)) {
    if ("head_commit" in item) {
      return item.head_commit.timestamp;
    }

    if ("pull_request" in item && item["action"] === "opened") {
      return item.pull_request.created_at;
    }

    if ("pull_request" in item && item["action"] === "closed") {
      return item.pull_request.closed_at;
    }

    if ("pull_request" in item) {
      return item.pull_request.updated_at;
    }

    if ("comment" in item) {
      return item.comment.created_at;
    }

    if ("issue" in item && item["action"] === "opened") {
      return item.issue.created_at;
    }

    if ("issue" in item && item["action"] === "opened") {
      return item.issue.created_at;
    }

    if ("issue" in item && item["action"] === "closed") {
      return item.issue.closed_at;
    }

    if ("issue" in item) {
      return item.issue.updated_at;
    }

    if ("commits" in item && item.commits.length) {
      return item.commits[0].timestamp;
    }
  } else if (isGitlab(item)) {
    if (item.commits && item.commits.length > 0) {
      const c = item.commits[item.commits.length - 1];
      return c.timestamp || c.created_at || c.authored_data || c.committed_data;
    }
    if ("object_attributes" in item) {
      if ("updated_at" in item.object_attributes)
        return item.object_attributes.updated_at;

      if ("timestamp" in item.object_attributes) {
        return item.object_attributes.timestamp;
      }
    }
  }

  // topstorie and slack
  if ("st_created_at" in item) {
    return u.timestampToString(item.st_created_at);
  } else if ("ts" in item) {
    return u.timestampToString(item.ts);
  }
  if ("event_ts" in item) {
    return u.timestampToString(item.event_ts);
  }

  return "";
}

export function getUser(item) {
  if ("st_user" in item && "st_source" in item) {
    return `${item.st_source}#${item.st_user}`;
  }

  if (isGitlab(item)) {
    if (item.user) {
      return item.user.name;
    }
    if (item.commits && item.commits.length > 0) {
      const c = item.commits[item.commits.length - 1];
      if (c.author && typeof c.author == "string") {
        return c.author;
      } else if (c.author && c.author.name) {
        return c.author.name;
      }

      return (
        c.author_name || c.author_email || c.committer_name || c.committer_email
      );
    }

    if ("user_name" in item) {
      return item.user_name;
    }
  } else if (isGithub(item)) {
    if ("user" in item) {
      return item.user;
    }

    if ("commits" in item && item.commits.length) {
      if (item.commits.length && "author" in item.commits[0]) {
        return item.commits[item.commits.length - 1].author.name;
      }
    }

    if ("sender" in item) {
      return item.sender.login;
    }

    if ("pusher" in item) {
      return item.pusher.name;
    }
  } else {
    // slack
    if ("user" in item) {
      return item.user;
    }

    if ("message" in item && "user" in item.message) {
      return item.message.user;
    }

    if ("message" in item && "edited" in item.message) {
      return item.message.edited.user;
    }
  }

  const src = item.st_source;
  if (src) {
    return src === "auto" ? "automatic" : src;
  }

  return "";
}
